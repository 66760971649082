span.font-normal {
    width: 100%;
    display: block;
    font-size: 13px !important;
}

span.duration-500.rotate-0 {
    opacity: 0.2;
}

.ddl-option {
    margin-left: 20px;
    margin-bottom: 10px;
}