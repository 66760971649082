.main-heading-social-media {
  font-size: 48px;
  text-align: center;
}

.main-root-social-media {
  margin-top: 90px;
}

.social-posts-div {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 650px) {
  .social-posts-div {
    flex-direction: column;
    align-items: center;
  }

  .facebook-feed-ui {
    height: 708px;
  }
}
